import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Column from '@frontend/ui-kit/Components/Column';
import Icon from '@frontend/ui-kit/Components/Icon';
import Input from '@frontend/ui-kit/Components/Input';
import Label from '@frontend/ui-kit/Components/Label';
import Row from '@frontend/ui-kit/Components/Row';
import Select from '@frontend/ui-kit/Components/Select';
import Text from '@frontend/ui-kit/Components/Text';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import Tooltip from '@frontend/ui-kit/Components/Tooltip';
import Footer from './Footer';
import { Form, Field } from '../../shared/FormComponents';
import Section from './Section';
import ExternalSftp from '../ExternalSftp';
import Encryption from './Encryption';
import Heading from '@frontend/ui-kit/Components/Heading';
import {
    getFormattedDataMapping,
    validateRequired,
    validateNotBlank,
    validateHeaderlessEntries
} from '../../../utils';
import { redirectTo } from '../../../actions/general';
import { initImportConfigForm, saveImportConfigForm, saveExternalSftp } from '../../../actions/importConfigs';
import {
    FORMS,
    FORM_GLOBAL_ERROR,
    ROUTES,
    IMPORT_METHOD_OPTIONS,
    ROWS_PER_RECORD_VALUES,
    TPA_DATA_PURPOSES,
    TPA_FILE_TYPES,
    HEADERLESS_TOOLTIP,
    MARKETPLACE_TOOLTIP
} from '../../../constants';
import './index.scss';

const validate = values => ({
    tpa_partner_id: validateRequired(values.tpa_partner_id),
    import_method: validateRequired(values.import_method),
    case_id: validateHeaderlessEntries(values.is_headerless, values.case_id),
    encounter_date: validateHeaderlessEntries(values.is_headerless, values.encounter_date),
    date_opened: validateHeaderlessEntries(values.is_headerless, values.date_opened),
    medical_plan_member_id: validateHeaderlessEntries(values.is_headerless, values.medical_plan_member_id) || validateNotBlank(values.medical_plan_member_id),
    data_source_internal_member_id: validateHeaderlessEntries(values.is_headerless, values.data_source_internal_member_id),
    group_id: validateHeaderlessEntries(values.is_headerless, values.group_id),
    relationship: validateHeaderlessEntries(values.is_headerless, values.relationship),
    social_security_number: validateHeaderlessEntries(values.is_headerless, values.social_security_number),
    first_name: validateHeaderlessEntries(values.is_headerless, values.first_name) || validateNotBlank(values.first_name),
    last_name: validateHeaderlessEntries(values.is_headerless, values.last_name) || validateNotBlank(values.last_name),
    patient_date_of_birth: validateHeaderlessEntries(values.is_headerless, values.patient_date_of_birth),
    employer_name: validateHeaderlessEntries(values.is_headerless, values.employer_name),
    case_type: validateHeaderlessEntries(values.is_headerless, values.case_type),
    case_management_status: validateHeaderlessEntries(values.is_headerless, values.case_management_status),
    case_manager_name: validateHeaderlessEntries(values.is_headerless, values.case_manager_name),
    case_manager_phone: validateHeaderlessEntries(values.is_headerless, values.case_manager_phone),
    case_manager_email: validateHeaderlessEntries(values.is_headerless, values.case_manager_email),
    case_description: validateHeaderlessEntries(values.is_headerless, values.case_description),
    icd10: validateHeaderlessEntries(values.is_headerless, values.icd10),
    icd10_description: validateHeaderlessEntries(values.is_headerless, values.icd10_description),
    member_engagement: validateHeaderlessEntries(values.is_headerless, values.member_engagement),
    care_level: validateHeaderlessEntries(values.is_headerless, values.care_level),
    referral_source: validateHeaderlessEntries(values.is_headerless, values.referral_source),
});

const CaseManagementForm = ({ partnerOptions }) => {
    const dispatch = useDispatch();
    const { id: configId } = useParams();
    const [initialValues, setInitialValues] = useState({});
    const [importConfigId, setImportConfigId] = useState(configId);
    const [importMethod, setImportMethod] = useState();
    const [isEdit, setIsEdit] = useState(!!importConfigId);
    const [isHeaderless, setIsHeaderless] = useState(false);
    const [isMarketplaceIdentifiable, setIsMarketplaceIdentifiable] = useState(false);
    const [isEncrypted, setIsEncrypted] = useState();
    const [isExternalSftp, setIsExternalSftp] = useState();

    const onSubmit = useCallback(
        async values => {
            const {
                id: configId,
                tpa_partner: tpaPartner,
                tpa_file_type: tpaFileType,
                data_purpose: dataPurpose,
                partnerOptions: partners,
                data_mappings: dataMappings,
                tpa_partner_id: tpaPartnerId,
                import_method: importMethod,
                has_multiple_rows_per_record: rowsPerRecord,
                join_key: joinKey,
                internal_notes: internalNotes,
                is_headerless: isHeaderless,
                is_marketplace_identifiable: isMarketplaceIdentifiable,
                is_encrypted_file: isEncrypted,
                vault_path: vaultPath,
                is_external_sftp: isExternalSftp,
                external_sftp_id: externalSftpId,
                external_sftp: {
                    credentials_link: credentialsLink,
                    directory_path: directoryPath,
                    filename_pattern: filenamePattern,
                    sync_frequency_in_hours: syncFrequencyInHours,
                    last_sync: lastSync
                } = {},
                ...dataMappingsFormValues
            } = values;

            const formDataMappings = Object.entries(dataMappingsFormValues).map(([fieldName, fieldValue]) => ({
                healthjoy_field: fieldName,
                data_field: fieldValue
            }));
            const formattedDataMappings = formDataMappings.map(getFormattedDataMapping).flat();

            const filterOutEmpties = mapping => {
                const { data_field: fieldValue } = mapping;

                return !!fieldValue;
            };

            const validDataMappings = formattedDataMappings.filter(filterOutEmpties);
            const importConfigRequestParams = {
                tpa_file_type: TPA_FILE_TYPES.caseManagement,
                data_purpose: TPA_DATA_PURPOSES.eventAutomation,
                tpa_partner_id: tpaPartnerId,
                import_method: importMethod,
                join_key: rowsPerRecord === ROWS_PER_RECORD_VALUES.multipleRowsPerRecord ? joinKey : null,
                data_mappings: validDataMappings,
                is_headerless: isHeaderless || false,
                is_marketplace_identifiable: isMarketplaceIdentifiable || false,
                is_encrypted_file: isEncrypted,
                vault_path: vaultPath || null,
                is_external_sftp: importMethod === 'Manual Upload' ? false : isExternalSftp,
                external_sftp_id: externalSftpId || null,
                internal_notes: internalNotes || null
            };

            if (isExternalSftp && importMethod === 'SFTP') {
                const todaysDate = new Date().toISOString();

                const externalSftpRequestParams = {
                    credentials_link: credentialsLink,
                    directory_path: directoryPath || '',
                    filename_pattern: filenamePattern,
                    sync_frequency_in_hours: syncFrequencyInHours,
                    last_sync: lastSync || todaysDate
                };

                const { data, isSuccess, submissionGlobalError } = await dispatch(
                    saveExternalSftp(externalSftpId, { ...externalSftpRequestParams })
                );

                if (!isSuccess) {
                    return { [FORM_GLOBAL_ERROR]: submissionGlobalError };
                }

                importConfigRequestParams.external_sftp_id = data.id;
            }

            const { data, isSuccess, isEdit, submissionGlobalError } = await dispatch(
                saveImportConfigForm(configId, { ...importConfigRequestParams })
            );
            const { id: importConfigId } = data;

            if (!isSuccess) {
                return { [FORM_GLOBAL_ERROR]: submissionGlobalError };
            }

            setInitialValues(values);
            setIsEdit(isEdit);
            setImportConfigId(importConfigId);
            setIsHeaderless(isHeaderless);
            setIsMarketplaceIdentifiable(isMarketplaceIdentifiable);
        },
        [dispatch]
    );

    useEffect(() => {
        (async () => {
            const { data } = await dispatch(initImportConfigForm(importConfigId));
            const {
                is_encrypted_file: isEncrypted,
                is_external_sftp: isExternalSftp,
                import_method: importMethod
            } = data;

            setInitialValues(data);
            setIsEncrypted(isEncrypted);
            setIsExternalSftp(isExternalSftp);
            setImportMethod(importMethod);
        })();
    }, [dispatch, importConfigId]);

    const onSubmitSuccess = useCallback(async () => {
        if (!isEdit) {
            dispatch(redirectTo(`${ROUTES.importConfigs}/${importConfigId}/edit?id=${importConfigId}`));
        }
    }, [dispatch, importConfigId, isEdit]);

    const onImportMethodChange = value => {
        setImportMethod(value);

        if (importMethod === 'Manual Upload') {
            setIsExternalSftp(false);
        }
    };

    const onHeaderlessChange = (event, form) => {
        event.persist();
        setIsHeaderless(isHeaderless => !isHeaderless);
        form.change('is_headerless', !isHeaderless);
    };

    const onMarketplaceIdentifiableChange = (event, form) => {
        event.persist();
        setIsMarketplaceIdentifiable(isMarketplaceIdentifiable => !isMarketplaceIdentifiable);
        form.change('is_marketplace_identifiable', !isMarketplaceIdentifiable);
    };

    const headerlessTooltipText = <Text className='information-paragraph'>{HEADERLESS_TOOLTIP.body}</Text>;

    const headerlessTooltip = (
        <div>
            Headerless
            <Tooltip isClickable content={headerlessTooltipText}>
                <Icon type='info-circle' />
            </Tooltip>
        </div>
    );

    const marketplaceTooltipText = <Text className='information-paragraph'>{MARKETPLACE_TOOLTIP.body}</Text>;

    const marketplaceTooltip = (
        <div>
            HealthJoy-generated Identifier
            <Tooltip isClickable content={marketplaceTooltipText}>
                <Icon type='info-circle' />
            </Tooltip>
        </div>
    );

    return (
        <div className='import-config-form'>
            <Column offset={0} sm={12}>
                <Form
                    name={FORMS.caseManagementImportConfig}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validate={validate}
                    onSubmitSuccess={onSubmitSuccess}
                >
                    {({ handleSubmit, submitError }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Section header='Data Source' offset={0} sm={12}>
                                    <Row className='import-config-form-upper-margin'>
                                        <Column sm={4}>
                                            <Label label='Source' type='select'>
                                                <Field name='tpa_partner_id'>
                                                    {props => <Select {...props} options={partnerOptions} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label='Import Method' type='select'>
                                                <Field name='import_method' onChange={onImportMethodChange}>
                                                    {props => <Select {...props} options={IMPORT_METHOD_OPTIONS} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        <Encryption isEncrypted={isEncrypted} />
                                        {importMethod === 'SFTP' && <ExternalSftp isExternalSftp={isExternalSftp} />}
                                    </Row>
                                </Section>
                                <Section header='Data Mapping' offset={0} sm={12} displaySeparator={false}>
                                    <Row className='import-config-form-upper-margin'>
                                        <Column sm={4}>
                                            <Label label={headerlessTooltip}>
                                                <Field name='is_headerless' onChange={onHeaderlessChange}>
                                                    {props => <Checkbox {...props} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label={marketplaceTooltip}>
                                                <Field name='is_marketplace_identifiable' onChange={onMarketplaceIdentifiableChange}>
                                                    {props => <Checkbox {...props} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                    </Row>
                                    <Heading
                                        gradation={6}
                                        fontWeight='semibold'
                                        className='import-config-form-section-heading'
                                    >
                                        Case Information
                                    </Heading>
                                    <Row>
                                        <Column sm={4}>
                                            <Label label='Case ID' type='input'>
                                                <Field name='case_id'>
                                                    {props => <Input {...props} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label='Case Type' type='input'>
                                                <Field name='case_type'>{props => <Input {...props} />}</Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label='Case Management Status' type='input'>
                                                <Field name='case_management_status'>{props => <Input {...props} />}</Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label='Case Description' type='input'>
                                                <Field name='case_description'>{props => <Input {...props} />}</Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label='Case Manager Name' type='input'>
                                                <Field name='case_manager_name'>{props => <Input {...props} />}</Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label='Case Manager Phone' type='input'>
                                                <Field name='case_manager_phone'>{props => <Input {...props} />}</Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label='Case Manager Email' type='input'>
                                                <Field name='case_manager_email'>{props => <Input {...props} />}</Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label='Encounter Date' type='input'>
                                                <Field name='encounter_date'>
                                                    {props => <Input {...props} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label='Date Opened' type='input'>
                                                <Field name='date_opened'>
                                                    {props => <Input {...props} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                    </Row>
                                    <Heading
                                        gradation={6}
                                        fontWeight='semibold'
                                        className='import-config-form-section-heading'
                                    >
                                        Member Information
                                    </Heading>
                                    <Row>
                                        <Column sm={4}>
                                            <Label label='Medical Plan Member ID' type='input'>
                                                <Field name='medical_plan_member_id'>
                                                    {props => <Input {...props} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label='Data Source Internal Member ID' type='input'>
                                                <Field name='data_source_internal_member_id'>{props => <Input {...props} />}</Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label='Member Engagement' type='input'>
                                                <Field name='member_engagement'>
                                                    {props => <Input {...props} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label='Employer Name' type='input'>
                                                <Field name='employer_name'>
                                                    {props => <Input {...props} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label='Group ID' type='input'>
                                                <Field name='group_id'>
                                                    {props => <Input {...props} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                    </Row>
                                    <Heading
                                        gradation={6}
                                        fontWeight='semibold'
                                        className='import-config-form-section-heading'
                                    >
                                        Patient Information
                                    </Heading>
                                    <Row>
                                        <Column sm={4}>
                                            <Label label='Relationship' type='input'>
                                                <Field name='relationship'>{props => <Input {...props} />}</Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label='Social Security Number' type='input'>
                                                <Field name='social_security_number'>{props => <Input {...props} />}</Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label='First Name' type='input'>
                                                <Field name='first_name'>{props => <Input {...props} />}</Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label='Last Name' type='input'>
                                                <Field name='last_name'>
                                                    {props => <Input {...props} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label='Patient Date of Birth' type='input'>
                                                <Field name='patient_date_of_birth'>
                                                    {props => <Input {...props} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label='Care Level' type='input'>
                                                <Field name='care_level'>
                                                    {props => <Input {...props} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label='Referral Source' type='input'>
                                                <Field name='referral_source'>
                                                    {props => <Input {...props} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                    </Row>
                                    <Heading
                                        gradation={6}
                                        fontWeight='semibold'
                                        className='import-config-form-section-heading'
                                    >
                                        Drug Information
                                    </Heading>
                                    <Row>
                                        <Column sm={4}>
                                            <Label label='ICD-10' type='input'>
                                                <Field name='icd10'>
                                                    {props => <Input {...props} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label='ICD-10 Description' type='input'>
                                                <Field name='icd10_description'>{props => <Input {...props} />}</Field>
                                            </Label>
                                        </Column>
                                    </Row>
                                </Section>
                                <Footer importConfigId={importConfigId} submitError={submitError} />
                            </form>
                        );
                    }}
                </Form>
            </Column>
        </div>
    );
};

CaseManagementForm.propTypes = {
    partnerOptions: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.number }))
};

export default React.memo(CaseManagementForm);
